.grid-container {
  display: grid;
  padding: 1px;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 75px;
  grid-gap: 1px;
  font-size: 25px;
  background-color: black;
}

.grid-cell {
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 5px;
}

.span-1 {
  grid-column: span 1;
}

.span-2 {
  grid-column: span 2;
}

.span-3 {
  grid-column: span 3;
}

.span-4 {
  grid-column: span 4;
}

.align-left {
  justify-content: left;
}

.align-center {
  justify-content: center;
}

.align-right {
  justify-content: right;
}

.valign-top {
  align-items: flex-start;
}

.valign-middle {
  align-items: center;
}

.valign-bottom {
  align-items: flex-end;
}
